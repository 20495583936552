require('normalize.css/normalize.css');
require('./styles/index.scss');
require('./libs/animit.min');
require('./libs/jquery.pudge.min.js');

$(() => {
    const $menu = $(".menu__wrapper").pudgeJS();

    $('.menu__button').click((event) => {
        event.preventDefault();
        $menu.pudgeJS('toggle');
    });
});